@import '../../theme/styles/scss/function';

.alert-modal {
  z-index: 4000 !important;

  &.ara-dialog .modal-content {
    border-radius: 0.5rem !important;
  }

  .modal-body {
    padding: 1rem 0.875rem;
  }

  .modal-message {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: toRem(144);

    p {
      margin-bottom: 0;
    }
  }

  .dialog-footer {
    padding: 0 0.875rem;
    padding-bottom: 1rem;
  }

  .ara-btn {
    &.single {
      min-width: 152px;
    }
  }
}
