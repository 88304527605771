@import '../../../theme/styles/scss/function';

.thumbnail {
  border-radius: 1.07vw;
  opacity: 0.3;

  &.active {
    opacity: 1;
  }

  & button {
    width: 100%;
    height: 100%;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: 0;

    display: flex;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    color: #000;
  }

  .thumbnail-body {
    padding: 0.6vw 1.6vw 1vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-text {
    font-size: toRem(14);
    line-height: toRem(20);
    font-weight: 600;
    text-align: center;
  }

  &-wrapper {
    width: 100%;
    background-color: #fff;
    padding: 0.8vw;
    padding-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
