@import '../../theme/styles/scss/variables';
@import '../../theme/styles/scss/function';

.btn-group.quantity-control {
  font-weight: 700;
  font-size: toRem(20);
  line-height: toRem(32);
  text-align: center;

  span {
    display: inline-block;
    padding: 0 toRem(4);
    position: relative;
    bottom: 2px;

    @media only screen and (min-width: 360px) {
      padding: 0 toRem(8);
    }
  }

  a {
    background-color: white;
    border: 2px solid rgba(217, 215, 210, 0.5);
    text-align: center;
    width: toRem(32);
    height: toRem(32);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    -webkit-appearance: none;

    -moz-appearance: none;

    appearance: none;
    outline: none;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }

    &.disabled {
      background-color: #ddd;
    }
  }
}
