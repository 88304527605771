@import '../../theme/styles/scss/function';

.thanks-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  height: -webkit-fill-available;
  height: var(--app-height);
  width: 100%;
  font-size: toRem(16);
  line-height: toRem(24);
  font-weight: 600;
  background: url('../../images/background_end_screen.png');
  background-repeat: no-repeat;
  color: white;
  background-size: cover;

  .content {
    padding-top: toRem(50);
  }
}
