@import '../../theme/styles/scss/function';
@import '../../theme/styles/scss/variables';

.dish-price {
  font-size: 1rem;

  .price-root {
    font-size: toRem(16);
    font-weight: 700;
    line-height: toRem(32);
    color: $color_contrast_text;
    margin-right: 4px;

    @media (min-width: 360px) {
      font-size: toRem(20);
    }
  }

  .price-root-value {
    font-weight: 900;
  }

  .price-tax {
    color: $text_secondary;
    font-weight: 700;
    font-size: toRem(14);
    line-height: toRem(24);
  }
}
