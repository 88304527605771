@import '../../theme/styles/scss/function';
@import '../../theme/styles/scss/variables';

.coupon-modal {
  z-index: 1051 !important;
  top: var(--position-modal-top) !important;
}

.coupon-form-modal {
  &__headtitle {
    width: 100%;
    padding: 0 1rem;
    color: #000000;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }

  &__lables {
    font-size: toRem(14) !important;
  }

  &__input-wrapper {
    padding-top: toRem(11);
    background: #ffffff;
    border-radius: 8px;
    min-height: toRem(56);
    input {
      text-align: left;
    }
    input::-webkit-input-placeholder {
      font-size: toRem(14);
      letter-spacing: normal;
      text-align: left;
      font-weight: normal;
    }
  }

  &__input-field {
    padding: 0 0.4rem;
    border: none;
    font-size: toRem(24);
    line-height: 1em;
    letter-spacing: 10pt;
    font-weight: bold;
    max-width: 100%;
    text-align: center;
    // -moz-appearance: none;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    background: #ffffff;
    // -webkit-appearance: none;
    box-shadow: inset 0px -0.5px 0px rgba(18, 18, 29, 0.1);
    border-radius: 8px;
    width: 100%;
  }

  &__content {
    font-size: toRem(14);
    .fw-bold {
      color: $text_secondary;
      letter-spacing: 3pt;
    }
  }

  &__promovalue {
    font-size: toRem(36);
    line-height: 1em;
    font-weight: bold;
    letter-spacing: 6pt;
    color: $text_primary;
  }

  &__description {
    color: $color_contrast_text;
    padding-top: toRem(16);
    font-size: toRem(14);
    display: flex;
    justify-content: center;
    line-height: toRem(24);
    font-weight: 400;
    margin: 0 auto;
    ol,
    ul {
      padding-left: toRem(15);
      margin-bottom: 0;
    }
  }

  &__footer {
    .btn {
      flex: 1;
      border-radius: 3vw;
      font-size: 1.5rem;
      line-height: 1.8em;
      white-space: nowrap;
      border: none;

      &:first {
        margin-right: 1rem;
      }

      &:nth-child(2) {
        margin-left: 1rem;
        background-color: #ff6400;
        padding-right: 8px;
      }
    }
  }
  .border-bottom-0 {
    padding-bottom: 0px;
  }
  .modal-body {
    padding-bottom: 0px;
  }
  .group-button {
    padding: toRem(16);
  }
  .p-10 {
    margin-left: toRem(8);
  }
}
