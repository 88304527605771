@import '../../../theme/styles/scss/variables';
@import '../../../theme/styles/scss/function';

.menubook-thumb-button {
  flex: 1 0;
  border: 2px solid $color_main;
  background: #fff;
  text-align: center;
  padding: toRem(2) 0 toRem(4);
  margin: 0 toRem(3);
  border-radius: toRem(17);
  cursor: pointer;
  appearance: none;
  outline: none;

  font-size: toRem(15);
  line-height: toRem(24);
  font-weight: 600;
  color: $color_main;
  overflow: hidden;

  &.active {
    color: #fff;
    border-color: $color_dark;
    background-color: $color_main;
  }

  &-loading {
    border-width: 2px !important;
    width: 1rem !important;
    height: 1rem !important;
    color: $color_dark;
  }
}
