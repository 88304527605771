@import '../../../../theme/styles/scss/function';
@import '../../../../theme/styles/scss/variables';
@import '../../../../theme/styles/scss/boxshadow';

.child-option-group {
  margin-left: auto;
  width: 90%;
  .selected-option-item {
    padding: toRem(10) toRem(8) toRem(10) toRem(16);
  }
  .collapse {
    .list-child-group-wrapper {
      padding: 0 !important;
      .menu-option {
        margin-bottom: 5px !important;
      }
    }
  }
  .collapse-child-button {
    color: white;
    display: flex !important;
    text-align: left;
    background-color: #b82602;
    padding: toRem(10) toRem(8) toRem(10) toRem(16);

    .order-name {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      font-size: toRem(14);
      line-height: toRem(24);
    }
    .arrow-down {
      background-color: #bf5020;
      height: 18px;
      transition: ease-in 300ms;
      &.show {
        path {
          transform-origin: 50% 50%;
          transform: rotate(180deg);
        }
      }
    }

    &.collapse .order-name-nav {
      transform: rotate(180deg);
    }

    button.delete-order {
      border: none;
      border-radius: 1vw;
      background-color: white;
      color: #ffa500;
    }
  }
}
