@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/variables';

$size: 40%;

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  box-sizing: content-box;
  background-color: #fff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: $color_main !important;
  border: toRem(2) solid #fff !important;
}

.swiper-lazy-preloader {
  border: 2px solid #f27900 !important;
  border-top-color: transparent !important;
}

.campaign-swiper {
  margin-bottom: toRem(16);
}

.campaign-thumb {
  border: 0.8vw solid #fff;
  border-radius: 2.13vw;
  text-align: center;

  & img {
    border-radius: 1.53vw;
    width: 100%;
  }
}

#top-menu {
  padding: toRem(16) toRem(20) 0;
}

#top-menu .thumbnail-block {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  .thumbnail {
    margin: 0 1vw;
    flex-basis: $size;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

#detail-campaign-block {
  margin: 1vw;
}

#campaign-name {
  text-align: left;
  font-weight: bold;
  padding: 1rem;
}

#campaign-image-block {
  position: relative;
  img {
    width: 100%;
  }
  div.order-btn {
    position: absolute;
    font-weight: bold;
    color: blue;
  }
}

div.see-all-wrapper {
  text-align: right;
  button#see-all {
    right: 0;
  }
}

@media screen and (max-width: 575.999px) {
  #top-menu .thumbnail-block .thumbnail .card-text {
    font-size: 1.2rem;
  }
  #campaign-name {
    font-size: 1.4rem;
  }
  button#see-all {
    font-size: 1.2rem;
  }
  div.order-btn {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 767.999px) {
  #top-menu .thumbnail-block .thumbnail .card-text {
    font-size: 1.4rem;
  }
  #campaign-name {
    font-size: 1.4rem;
  }
  button#see-all {
    font-size: 1.4rem;
  }
  div.order-btn {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.999px) {
  #top-menu .thumbnail-block .thumbnail .card-text {
    font-size: 1.6rem;
  }
  #campaign-name {
    font-size: 2rem;
  }
  button#see-all {
    font-size: 1.6rem;
  }
  div.order-btn {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 992px) {
  #top-menu .thumbnail-block .thumbnail .card-text {
    font-size: 1.8rem;
  }
  #campaign-name {
    font-size: 2.4rem;
  }
  button#see-all {
    font-size: 1.8rem;
  }
  div.order-btn {
    font-size: 1.6rem;
  }
}
