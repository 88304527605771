@import './function';
@import './background';

/*** Radio Input ***/
.ara-radio {
  display: inline-block;
  position: relative;
  padding-left: toRem(30);
  margin-bottom: 0;
  cursor: pointer;
  font-size: inherit;
  line-height: toRem(30);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  min-height: toRem(30);

  &-text {
    margin-left: toRem(8);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: toRem(30);
    width: toRem(30);
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #d1d1d1;
    vertical-align: middle;

    &:after {
      content: '';
      position: absolute;
      display: none;

      display: block;
      top: 2px;
      left: 2px;
      width: toRem(22);
      height: toRem(22);
      border-radius: 50%;
      background: #e9eff2;
    }
  }

  & input:checked ~ .checkmark:after {
    @include bg_linear_vertical;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }
}

/*** Checkbox Input ***/
.ara-checkbox {
  display: inline-block;
  position: relative;
  padding-left: toRem(30);
  margin-bottom: 0;
  cursor: pointer;
  font-size: inherit;
  line-height: toRem(30);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  min-height: toRem(30);

  &-text {
    margin-left: toRem(8);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: toRem(30);
    width: toRem(30);
    background-color: #fff;
    // border-radius: 50%;
    border: 2px solid #d1d1d1;
    vertical-align: middle;

    &:after {
      content: '';
      position: absolute;
      display: none;

      display: block;
      top: 2px;
      left: 2px;
      width: toRem(22);
      height: toRem(22);
      // border-radius: 50%;
      background: #e9eff2;
    }
  }

  & input:checked ~ .checkmark:after {
    @include bg_linear_vertical;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }
}
