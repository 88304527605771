@import '../../../theme/styles/scss/background';
@import '../../../theme/styles/scss/variables';
@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/boxshadow';
.remain-button {
  background: $color_dark;
  padding: toRem(4);
}

.remain-button-wrapper {
  display: flex;
  padding: toRem(3) 0 toRem(5) toRem(18);
  height: toRem(46);
}

.remain-button-text {
  font-size: toRem(12);
  font-weight: 600;
  color: $text_white;
  line-height: 1em;
  @media (min-width: 400px) {
    font-size: toRem(12);
  }

  @media (min-width: 768px) {
    font-size: toRem(16);
  }
}

.remain-button-icon {
  padding-right: toRem(20);
}

.remain-button-time {
  font-size: 1.4rem;
  line-height: 1.4em;
  font-weight: bold;
  text-align: center;
}

.remain-button-value {
  position: relative;
  bottom: -1px;
  font-size: toRem(18);
  display: inline;
  color: $color_light;
  font-weight: 800;
  padding: 0 2px 0 1px;

  @media (min-width: 768px) {
    font-size: toRem(20);
  }
}
.remain-button-sub-text {
  margin-bottom: 3px;
}
