@import '../../../theme/styles/scss/function';
@import '../../../theme/styles/scss/boxshadow';

.menubook-bar {
  position: sticky;
  left: 0;
  bottom: toRem(42);
  z-index: 999;
  width: 100%;
  padding: 0 toRem(8);
  &-swiper {
    white-space: nowrap;
    background-color: #fff;
    border-radius: toRem(23);
    padding: toRem(6) toRem(3) !important;
    @include box_shadow_tabbar;
    .menubook-thumb-button {
      width: 90px;
    }
  }
  &-wrapper {
    display: flex;
    background-color: #fff;
    border-radius: toRem(23);
    padding: toRem(6) toRem(3);
    @include box_shadow_tabbar;
  }
}
