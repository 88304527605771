@import '../../../theme/styles/scss/boxshadow';
@import '../../../theme/styles/scss/variables';
@import '../../../theme/styles/scss/function';

.course-intro-modal {
  top: var(--position-modal-top) !important;
  z-index: 1051;

  .modal-dialog {
    max-width: 44rem;
  }

  .modal-btn-close-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: toRem(16);
  }

  .modal-btn-close {
    z-index: 10;
    width: toRem(30);
    height: toRem(30);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    appearance: none;
    outline: none;
    background-color: $color_dark;
    @include box_shadow_button;
    opacity: 1;
  }

  .course-intro-modal-body {
    min-height: 10rem;

    img {
      max-width: 100%;
      height: auto;
      display: inline-block;
    }
  }
}
