@import '../../theme/styles/scss/boxshadow';
@import '../../theme/styles/scss/function';
@import '../../theme/styles/scss/function';

.cart-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  @include box_shadow_tabbar;
  display: flex;
  justify-content: center;
  align-items: center;
  height: toRem(44);
  touch-action: none;
  -ms-touch-action: none;
}

.cart-header .area-turn-back {
  position: absolute;
  left: 0;
  padding: toRem(10);
  display: flex;
  align-items: center;
  font-size: toRem(14);
  color: #000;

  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border: none;
  outline: none;
  background-color: transparent;

  &:disabled {
    svg {
      opacity: 0.5;
    }
  }
}

.cart-header .area-turn-back .text {
  margin-left: toRem(6);
  margin-top: -0.1rem;
}

.cart-header .logo {
  padding: toRem(8);
}
