@import './function';
@import './background';
@import './variables';
@import './boxshadow';

.ara-btn {
  font-weight: 700;
  font-size: toRem(16);
  line-height: toRem(24);
  text-align: center;
  text-decoration: none;

  padding: toRem(12) 0 toRem(16);

  appearance: none;
  border: toRem(2) solid transparent;
  border-radius: toRem(28);

  &:disabled {
    opacity: 0.5;
  }

  &-primary {
    color: white;
    border-color: white;
    @include bg_linear_vertical;
    @include box_shadow_button;
  }

  &-secondary {
    position: relative;
    background-clip: padding-box;
    color: $color_main;
    background-color: #fff;
    @include box_shadow_button;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: toRem(-2);
      border-radius: inherit;
      @include bg_linear(180deg, #feaf02, #ff8000);
    }
  }

  &-outline {
    @include bg_linear(180deg, #feaf02, #ff8000);
    padding: toRem(2);
    border: none;
    @include box_shadow_button;

    & > div {
      width: 100%;
      height: 100%;
      padding: toRem(12) 0 toRem(16);
      background-color: white;
      border-radius: inherit;
      color: $color_main;
    }
  }

  &-dark {
    color: $color_light;
    background-color: $text_primary;
    border-color: white;
    @include box_shadow_button;
  }

  &-link {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    outline: none;
    border: none;
  }
}
