@import './scss/function';

body {
  margin: 0;
  font-family: 'Murecho', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.full-height {
    overflow: hidden;
  }
}

html,
body {
  -webkit-overflow-scrolling: touch !important;
}

img {
  max-width: 100%;
  height: auto;
}

/** custom boostrap **/
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out !important;
  transform: translate(0, 30%) !important;
}

.modal.show .modal-dialog {
  transform: none !important;
}

.modal-dialog {
  margin-left: toRem(17);
  margin-right: toRem(17);

  &.modal-fullscreen {
    margin: 0 !important;

    .modal-content {
      -webkit-border-top-left-radius: 1rem;
      -webkit-border-top-right-radius: 1rem;
      -moz-border-radius-topleft: 1rem;
      -moz-border-radius-topright: 1rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;

      overflow: hidden;
      transform: translateZ(0);
    }
  }

  @media only screen and (min-width: 576px) {
    margin: toRem(17) auto;
  }
}

.dish-modal {
  .modal-fullscreen .modal-body {
    padding: 0;
    overscroll-behavior: contain;
  }
}

.modal-footer {
  border-top: none;
}
