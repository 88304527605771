.error-page {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}
