@import '../../theme/styles/scss/background';
@import '../../theme/styles/scss/variables';
@import '../../theme/styles/scss/function';

.header-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;

  #smart-order.member & {
    top: toRem(44);
  }
}

#top-section {
  position: relative;
  background-color: white;

  &.member {
    top: 44px;
  }

  .freq-button {
    border-radius: 2.4rem;
    font-size: 1.05rem;
  }

  .header-icon-wrapper {
    img {
      padding: 0 0 0.5rem 0;
    }
    div {
      font-size: 1.1rem;
    }
  }
}

.header {
  font-size: 0.875rem;
  position: relative;
}

.coupon-button {
  color: $text_primary;
  line-height: 1em;
  text-decoration: none;
  padding: toRem(10);

  &:hover {
    color: $text_primary;
    cursor: pointer;
  }

  &__text {
    margin-right: toRem(4);
    font-size: toRem(11);

    @media screen and (min-width: 360px) {
      font-size: toRem(13);
    }
  }
}

.search-button {
  color: $text_primary;
  line-height: 1em;
  text-decoration: none;
  padding: toRem(10);

  &:hover {
    color: $text_primary;
    cursor: pointer;
  }

  &__text {
    margin-left: 0.375rem;
    margin-bottom: 0.1rem;
  }
}

#header-mode-name {
  position: -webkit-sticky;
  position: sticky;
  display: block;
  z-index: 2;
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: #730000;
  text-align: left;
  font-weight: bold;
}

#header-mode-image {
  display: block;
  width: 100%;
}

.form-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: toRem(4) toRem(8);
  background-color: white;

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding: toRem(2) toRem(40) toRem(2) toRem(44);
    border: 1px solid $color_main;
    border-radius: toRem(18);
    overflow: hidden;
    transform: translateZ(0);
    background-color: $color_background;

    &.has-value {
      background-color: #fff;
    }
  }

  &-icon {
    position: absolute;
    top: -1px;
    left: toRem(12);
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 toRem(9);
    cursor: pointer;
  }

  &-input {
    width: 100%;
    height: 100%;
    padding-left: 0;
    border: none;

    font-size: toRem(16);
    line-height: 100%;
    padding-bottom: toRem(3);
    font-weight: 500;
    color: $color_contrast_text;
    background-color: transparent;

    &:focus {
      border: none;
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: $text_disabled;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: $text_disabled;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $text_disabled;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: $text_disabled;
      opacity: 1;
    }

    &::placeholder {
      color: $text_disabled;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $text_disabled;
    }

    &::-ms-input-placeholder {
      color: $text_disabled;
    }
  }
}

.language-button {
  color: $text_primary;
  line-height: 1em;
  text-decoration: none;
  padding: toRem(10);
  min-width: toRem(100);

  &:hover {
    color: $text_primary;
    cursor: pointer;
  }

  &__text {
    margin-left: toRem(2);
    margin-right: toRem(2);
    margin-bottom: 0.1rem;
    font-size: toRem(11);

    @media screen and (min-width: 360px) {
      font-size: toRem(13);
    }
  }
}

.language-selector {
  position: absolute;
  top: toRem(44);
  left: toRem(5);
  z-index: 10;
  background: #fff;
  width: toRem(120);
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;

  &.show {
    height: 246px;
  }

  &-item {
    padding: toRem(10);
    text-align: center;

    &.selected {
      background-color: $color_light;
      color: $color-dark;
    }
  }
}
