@import '../../theme/styles/scss/function';
@import '../../theme/styles/scss/variables';
@import '../../theme/styles/scss/background';

#order-history {
  flex: 1;
  padding: toRem(16) toRem(20) 0 toRem(20);
  overflow: auto;
  .card {
    border: toRem(3) solid $text_white;
    border-radius: toRem(4);
  }
  .card img,
  .card .img-cover,
  .card .img-cover span {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .card-content {
    padding-top: toRem(5);
    padding-left: toRem(14);
    padding-bottom: toRem(24);
    padding-right: toRem(10);
  }
  .card .card-content h3 {
    font-size: toRem(14);
    line-height: toRem(20);
    font-style: normal;
    font-weight: 600;
    color: $color_contrast_text;
    margin-bottom: toRem(4);
  }
  .card .card-content .order-status {
    color: white;
    height: toRem(20);
    padding-bottom: toRem(2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-align: center;
    font-size: toRem(11);
    line-height: 100%;
    margin-bottom: toRem(4);
    &-cooking,
    &-done_preparing {
      @include bg_linear_horizontal;
    }
    &-served {
      background-color: $text_disabled;
    }
  }
  .card .card-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: toRem(20);
    margin-bottom: toRem(4);
    font-weight: 600;
  }
  .card .card-content ul li {
    font-size: toRem(12);
  }
  .card .card-content ul li::before {
    content: '\A';
    width: toRem(4);
    height: toRem(4);
    border-radius: 50%;
    background: $color_contrast_text;
    display: inline-block;
    margin: toRem(2) toRem(5);
  }
  .dish-card {
    margin-bottom: toRem(16);
    display: flex;
    min-height: -webkit-min-content;
    min-height: -moz-min-content;
    min-height: min-content;
    .dish-price {
      line-height: 1em;

      .price-root {
        font-size: toRem(12);
        line-height: 1em;

        @media (min-width: 360px) {
          font-size: toRem(14);
        }
      }

      .price-tax {
        font-size: toRem(10);
        line-height: 1em;

        @media (min-width: 360px) {
          font-size: toRem(12);
        }
      }
    }
  }
  .card .card-quantity {
    padding-bottom: toRem(5);
    .text-quantity {
      color: $text_secondary;
      font-weight: 400;
      font-size: toRem(12);
      line-height: toRem(24);
      margin-left: toRem(12);
      margin-right: toRem(8);
    }
    .value-quantity {
      color: $text_primary;
      font-weight: 600;
      font-size: toRem(14);
      line-height: toRem(20);
      margin-bottom: toRem(2);
    }
  }
}

.cart-footer {
  margin-top: auto;
  -webkit-overflow-scrolling: touch !important;
  touch-action: none;
  background-color: $text_white;
  border-top: toRem(2) solid $color_background;
  padding: toRem(21) toRem(16) toRem(34) toRem(20);

  -webkit-border-top-left-radius: 0rem;
  -webkit-border-top-right-radius: 0rem;
  -moz-border-radius-topleft: 0rem;
  -moz-border-radius-topright: 0rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;

  .cart-price {
    margin-bottom: toRem(5);
  }

  .total-price {
    align-items: center;
    text-align: right;
    padding-right: toRem(8);
  }

  .price-root-value {
    color: $color_dark;
  }

  .total-price-text {
    font-size: toRem(14);
    font-style: normal;
    font-weight: 600;
    line-height: toRem(20);
    padding-left: toRem(4);
    display: flex;
    align-items: center;
  }

  .button-cart-back {
    padding-right: toRem(8);
    button {
      width: 100%;
      font-weight: 700;
      font-size: toRem(16);
      line-height: toRem(24);
    }
  }
  .button-cart-order {
    padding-left: toRem(8);
    button {
      width: 100%;
      font-weight: 700;
      font-size: toRem(16);
      line-height: toRem(24);
    }
  }
}
