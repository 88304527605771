@mixin bg_linear($angle: 360deg, $main-color: #fa9200, $second-color: #ff401a) {
  background: linear-gradient($angle, $main-color 0%, $second-color 100%);
}

@mixin bg_linear_vertical() {
  @include bg_linear();
}

@mixin bg_linear_horizontal() {
  @include bg_linear(90deg);
}
