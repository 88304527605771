/* COLOR */
$color_main: #f27900;
$color_dark: #d94800;
$color_light: #ffef40;
$color_contrast_text: #000;
$color_background: #f7f5ef;
$color_border: rgba(18, 18, 29, 0.1);

/* TEXT COLOR */
$text_primary: #344451;
$text_secondary: #707070;
$text_disabled: #a6a6a6;
$text_white: #fff;
